import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import { message } from 'ant-design-vue'
import Home from '../views/Home.vue'
import TemplateDetail from '../views/TemplateDetail.vue'
import Index from '../views/Index.vue'
import Works from '../views/Works.vue'
import Pay from '../views/Pay.vue'
// import Pay2 from '../views/Pay2.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import PayAli from '../views/PayAli.vue'
import Cooperation from '../views/Cooperation.vue'
import store from '../store'
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
      children: [
        { path: '', name: 'home', component: Home, meta: { title: '欢迎来到龙骨动画' } },
        { path: 'works', name: 'works', component: Works, meta: { title: '我的作品', requiredLogin: true, } },
        {
          path:'/pay',
          name:'pay',
          component: Pay,
          meta:{title:'支付'}
        },
        {
          path:'/about',
          name:'about',
          component: About,
          meta:{title:'关于我们'}
        },
        {
          path:'/contact',
          name:'contact',
          component: Contact,
          meta:{title:'联系我们'}
        },
        {
          path:'/cooperation',
          name:'cooperation',
          component: Cooperation,
          meta:{title:'商务合作'}
        },
        {
          path: '/payAli',
          name: 'payAli',
          component:PayAli,
          meta:{title:'扫码支付'}
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
      meta: { redirectAlreadyLogin: true, title: '登录', disableLoading: true }
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue'),
      meta: { redirectAlreadyLogin: true, title: '注册', disableLoading: true }
    },
    {
      path: '/forgetPassword',
      name: 'forgetPassword',
      component: () => import(/* webpackChunkName: "forgetPassword" */ '../views/ForgetPassword.vue'),
      meta: { redirectAlreadyLogin: true, title: '忘记密码', disableLoading: true }
    },
    {
      path: '/rights',
      name: 'rights',
      component: () => import(/* webpackChunkName: "rights" */ '../views/Rights.vue'),
      meta: { title: '用户协议' }
    }
  ]
})

router.beforeEach(async (to, from) => {
  const { user } = store.state
  const { token, isLogin } = user
  const { redirectAlreadyLogin, requiredLogin, title } = to.meta
  if (title) {
    document.title = title
  }
  if (!isLogin) {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        await store.dispatch('fetchCurrentUser')
        if (redirectAlreadyLogin) {
          return '/'
        }
      } catch {
        message.error('登陆状态已过期 请重新登陆', 2)
        store.commit('logout')
        return '/login'
      }
    } else {
      if (requiredLogin) {
        return '/login'
      }
    }
  } else {
    if (redirectAlreadyLogin) {
      return '/'
    }
  }
})
export default router
