
import { defineComponent, computed, onMounted, ref, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { GlobalDataProps } from '../store/index'
import WorksList from '../components/WorksList.vue'
import useLoadMore from '../hooks/useLoadMore'
import axios from 'axios'
import { message } from 'ant-design-vue'
import { gotoEditor } from '../utils/utils'

export default defineComponent({
  components: {
    WorksList
  },
  setup () {
    const store = useStore<GlobalDataProps>()
    const router = useRouter()
    const works = computed(() => store.state.templates.works)
    const total = computed(() => store.state.templates.totalWorks)
    const user = computed(() => store.state.user.data)
    const isLoading = computed(() => store.getters.isOpLoading('fetchWorks'))
    const isTemplate = ref(0)
    const searchParams =  computed(() => ({ pageIndex: 0, pageSize: 20, isTemplate: isTemplate.value }))
    const collapsed = ref<boolean>(false)

    onMounted(() => {
      store.dispatch('fetchWorks',  { searchParams: searchParams.value })
    })
    const { isLastPage, loadMorePage, isFirstPage, 
    loadPrevPage, pageIndex, requestParams, goToPage, totalPage } = useLoadMore('fetchWorks', total, searchParams.value)
    const onRename = (id: string, name: string) => {
      console.log('rename', id, name)
      store.dispatch('renameWork', { data: { uuid:id, name: name }})
    }
    const onDelete = (id: string) => {
      store.dispatch('deleteWork', { urlParams: { id: id }})
    }
    const onNewWork = () => {
      axios.post('/work/new' ).then((res) => {
        if (res.data && res.data.data) {
          const workId = res.data.data.uuid
          gotoEditor(workId)
          store.dispatch('fetchWorks',  { searchParams: searchParams.value })
        }
        else {
          message.error('创建作品失败')
        }
      }).catch((err) => {
        console.log('err', err)
      })
    }
    const onCopy = (id: number) => {
      store.dispatch('copyWork', id).then(({ data }) => {
        router.push(`/editor/${data.id}`)
      })
    }
    const changeCategory = (key: any) => {
      isTemplate.value = key
      pageIndex.value = 0
      requestParams.isTemplate = key
      nextTick(() => {
        store.dispatch('fetchWorks',  { searchParams: searchParams.value })
      })
    }
    const expireDate = new Date(user.value.dragonbonesExpire || Date.now()).toLocaleString()
    const isExpired = new Date(user.value.dragonbonesExpire || Date.now()).getTime() < Date.now()
    const isSpaceFull = (user.value.usedSpace || 0) > (user.value.dragonbonesSpace || 0)
    return {
      works,
      user,
      expireDate,
      onDelete,
      onRename,
      onCopy,
      isLoading,
      total,
      changeCategory,
      loadMorePage,
      isLastPage,
      isFirstPage,
      loadPrevPage,
      pageIndex,
      goToPage,
      totalPage,
      onNewWork,
      collapsed,
      isExpired,
      isSpaceFull
    }
  }
})
