
import { Card, Modal } from 'ant-design-vue'
import { defineComponent, watch, ref, onMounted, onUnmounted } from 'vue'
import { GlobalDataProps } from '../store/index'
import { Store, useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { GoodsData } from '../store/pay'
import QRCode from 'qrcode'
export default defineComponent({
  components: { Card },
  setup() {
    const store = useStore<GlobalDataProps>()
    const router = useRouter()
    const goods = ref(store.state.pay.data.goods)
    const order = ref(store.state.pay.data.order)
    const payUrl = ref(store.state.pay.data.payUrl)
    const price = ref(store.state.pay.data.price)
    const payStatus = ref(store.state.pay.data.payStatus)
    const isSuccessModalVisible = ref(false)
    if (!goods.value) {
      store.dispatch('getGoods')
    }

    watch(
      () => store.state.pay.data.goods,
      (newGoods) => {
        goods.value = newGoods
        console.log("goods", goods.value)
      }
    )
    const isModalVisible = ref(false)
    const selectedItem = ref<GoodsData | null>(null)
    const couponCode = ref('')
    const recommenderPhone = ref('')
    const phoneError = ref(false)
    const qrCodeUrl = ref('')
    let timer: NodeJS.Timeout | null = null
    let startTimer = false
    const getWXPayCode = async () => {
      const urlParams = {
        goodsId: selectedItem.value?._id,
        couponCode: couponCode.value,
        recommenderPhone: recommenderPhone.value
      }
      console.log("urlParams", urlParams)
      await store.dispatch('getWXPayCode', {searchParams: urlParams})
    }

    const generateQRCode = async (url: string) => {
      try {
        qrCodeUrl.value = await QRCode.toDataURL(url)
        console.log("qrCodeUrl", qrCodeUrl.value)
      } catch (err) {
        console.error(err)
      }
    }
    watch(
      [() => store.state.pay.data.order, ()=> store.state.pay.data.payUrl, ()=> store.state.pay.data.price, ()=> store.state.pay.data.payStatus],
      ([newOrder, newPayUrl, newPrice]) => {
        order.value = newOrder as any
        payUrl.value = newPayUrl as string
        price.value = newPrice as number
        payStatus.value = store.state.pay.data.payStatus
        generateQRCode(payUrl.value)
      }
    )

    const startCheckOrder = () => {
      if (startTimer) {
        return
      }
      startTimer = true
      console.log("startCheckOrder", order.value)
      timer = setInterval(() => {
        if(order.value && order.value._id) {
          store.dispatch('getPayStatus', {searchParams: {orderId: order.value._id}}).then(() => {
            console.log("payStatus", payStatus.value)
            if (payStatus.value === 'success') {
              clearInterval(timer as any)
              timer = null
              startTimer = false
              isModalVisible.value = false
              isSuccessModalVisible.value = true
              store.dispatch('fetchCurrentUser')
            }
          })
        }
      }, 5000)
    }
    const stopCheckOrder = () => {
      if(timer) {
        console.log("stopCheckOrder", timer)
        clearInterval(timer)
        timer = null
        startTimer = false
      }
    }

    const gotoPay = (index: number) => {
      // 如果没有登录，跳转到登录页面
      if (!store.state.user.isLogin) {
        router.push({ path: '/login', query: { redirect: router.currentRoute.value.fullPath } })
      } else {
        // 弹框支付
        if (goods.value) {
            selectedItem.value = goods.value[index]
          }
        getWXPayCode().then(() => {
          isModalVisible.value = true
          startCheckOrder()
        })
        isModalVisible.value = true
      }
    }
    const onCouponChange = (e: any) => {
      qrCodeUrl.value = ''
      price.value = 0
      couponCode.value = e.target.value
      getWXPayCode()
    }
    const onRecommenderChange = (e: any) => {
      if (!/^1[3-9]\d{9}$/.test(recommenderPhone.value)) {
        phoneError.value = true
      } else {
        phoneError.value = false
        recommenderPhone.value = e.target.value
        getWXPayCode()
      }
      
    }
    
    const onPaySuccess = () => {
      isSuccessModalVisible.value = false
      router.push('/')
    }
    const onCancelPay = () => {
      return new Promise((resolve, reject) => {
        Modal.confirm({
          maskClosable: false,
          title: '确定要取消支付吗？',
          onOk() {
            isModalVisible.value = false
            stopCheckOrder()
            resolve(true)
          },
          onCancel() {
            reject(false)
          }
        })
      })
    }
    // const onTestPay = () => {
    //   if (order.value) {
    //     store.dispatch('testPay', {data: {orderId: order.value._id}})
    //   }
    // }
    onUnmounted(() => {
      stopCheckOrder()
    })
    return {
      goods,
      gotoPay,
      price,
      qrCodeUrl,
      isModalVisible,
      selectedItem,
      couponCode,
      recommenderPhone,
      phoneError,
      onCouponChange,
      onRecommenderChange,
      isSuccessModalVisible,
      onPaySuccess,
      order,
      onCancelPay,
    }
  },
  methods: {
    // gotoPay(index: number) {
    //   const store = useStore<GlobalDataProps>()
    //   // 如果没有登录，跳转到登录页面
    //   if (!store.state.user.isLogin) {
    //     this.$router.push('/login')
    //   } else {
    //     // 弹框支付
    //   }
    // }
  }
})
