<template>
    <div class="nezha-home">
        <section>
            <div class="videoSec">

                <video class="slider-video" preload="auto" style="visibility: visible; width: 100%; height: auto;">
                    <source type="video/mp4" src="video/db_720.mp4">
                </video>
                <div class="bgPattern"></div>
                <div class="videoCon">
                    <div class="tableDisplay">
                        <div class="vCenter">
                            <h1 class="dragonbone-title">龙骨动画</h1>
                            <h2 class="dragonbone-feature"><span id='kaiyuan'>AI辅助动画</span><span
                                    style="margin-left: 2px;width: 1px; border-left: 2px solid #6fdb88;"></span>&nbsp;</h2>
                            <div class="des">
                                <p>面向设计师的动画创作平台</p>
                                <p>更少的美术成本，更生动的动画效果</p>
                                <p>多语言支持，一次制作，全平台发布</p>
                            </div>
                            <div class="btnPlay">
                                <a :href="signUpUrl">马上试用 <img src="../assets/img/play.png" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="downDisplay">
                    </div>
                </div>
            </div>
        </section>
        <section class="oneFeature">
            <div class="container">
                <div class="col-md-3">
                    <div style="margin-top: 40px; margin-left: 0px;">
                        <a :href="loginUrl">
                            <h3>动画解决方案</h3>
                            <p>打通动画设计和游戏开发工作流。</p>
                            <p>支持导入导出多种动画格式。</p>
                            <p>支持发布多种数据格式。</p>
                            <p style="color: #5fdab5;">立刻登录 ›</p>
                        </a>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="../assets/img/feature.png" style="margin-left: -100px; width: 120%;" />
                </div>
                <div class="col-md-3" style="    padding-left: 17px;">
                    <div style="margin-top: 180px;"></div>
                    <a :href="signUpUrl">
                        <h3>web版本编辑器</h3>
                        <p>打开即用，无需下载安装。</p>
                        <p>无需动画基础，轻松实现丰富特效。</p>
                        <p>云存储，何时何地，尽显创意。</p>
                        <p style="color: #5fdab5;">立刻注册 ›</p>
                    </a>
                </div>
            </div>
        </section>
        <section class="three">
            <div class="container">
                <h2>编辑器功能全面</h2>
                <div class="">
                    <div class="texing22 texing">
                        <div class="row">
                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/bone.gif">
                                    <span class="gongneng-name">&nbsp;骨骼操作</span>
                                    <div class="gongneng-span-name">
                                        <span class="span-name2">为图片绑定骨骼制作动画，制作角色动作更方便，动作更逼真，动画更流畅。</span>
                                    </div>
                                </div>
                            </div>


                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1 center-block">
                                    <img src="../assets/img/animation/ai.gif">
                                    <span class="gongneng-name">&nbsp;AI生成动画</span>
                                    <div class="gongneng-span-name">
                                        <span class="span-name2">
                                            龙骨已经接入DeepSeek。<br>在大模型的加持下，支持AI生成动画，一句话生成动画，让动画制作更加简单。
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/timeline.gif">
                                    <span class="gongneng-name">&nbsp;时间轴</span>
                                    <div class="gongneng-span-name">
                                        <span class="span-name2">时间轴是动画制作的核心，这里可以宏观的调节各个元件动画的关键帧，调节播放速度、动画补间等细节。</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/ffd.gif">
                                    <span class="gongneng-name">&nbsp;网格和自由变形</span>
                                    <div class="gongneng-span-name">
                                        <span
                                            class="span-name2">在图片矩形边界内自定义多边形，提高纹理集的空间使用率。通过移动网格点来变形图片，实现网格的扭曲，拉伸，转面等伪3D效果。</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/ik.gif">
                                    <span class="gongneng-name">&nbsp;IK骨骼约束</span>
                                    <div class="gongneng-span-name">
                                        <span
                                            class="span-name2">在骨骼动画中可以通过反向动力学的方式为角色摆姿势，建立反向动力学约束，使得动作的编辑操控更方便，生成的动作更自然更逼真。</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/rigging.gif">
                                    <span class="gongneng-name">&nbsp;骨骼权重和蒙皮动画</span>
                                    <div class="gongneng-span-name">
                                        <span class="span-name2">将网格中的点绑定骨骼权重，骨骼的运动带动网格变形，产生图片整体的弯曲，飘动等效果。</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/curve.gif">
                                    <span class="gongneng-name">&nbsp;曲线编辑器</span>
                                    <div class="gongneng-span-name">
                                        <span class="span-name2">在曲线编辑器中可以通过调整贝塞尔曲线来控制两帧之间的差值，以实现栩栩如生的动画效果。</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/onion.gif">
                                    <span class="gongneng-name">&nbsp;洋葱皮工具</span>
                                    <div class="gongneng-span-name">
                                        <span class="span-name2">使用洋葱皮功能可以同时看到前后若干帧的影图，方便更加精准的调节动画细节。</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/subArmature.gif">
                                    <span class="gongneng-name">&nbsp;骨架嵌套</span>
                                    <div class="gongneng-span-name">
                                        <span class="span-name2">项目中可以创建多个骨架并进行任意的嵌套重用。</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/surface.gif">
                                    <span class="gongneng-name">&nbsp;网格变形器</span>
                                    <div class="gongneng-span-name">
                                        <span class="span-name2">网格变形器，做和live2d一样的动画效果</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/autoMesh.gif">
                                    <span class="gongneng-name">&nbsp;自动生成网格</span>
                                    <div class="gongneng-span-name">
                                        <span
                                            class="span-name2">根据图片的形状，自动生成边框和填充三角形</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/pathConstrain.gif">
                                    <span class="gongneng-name">&nbsp;路径约束</span>
                                    <div class="gongneng-span-name">
                                        <span
                                            class="span-name2">在你的道路上永不出轨。<br>支持路径约束，让骨骼沿着轨道前进</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/shape.gif">
                                    <span class="gongneng-name">&nbsp;矢量形状</span>
                                    <div class="gongneng-span-name">
                                        <span
                                            class="span-name2">爱你的形状想怎么变就怎么变。<br>支持矢量形状，和形状变形动画</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/imageMask.gif">
                                    <span class="gongneng-name">&nbsp;位图遮罩</span>
                                    <div class="gongneng-span-name">
                                        <span
                                            class="span-name2">让你的轮廓成为我世界的滤镜<br>支持位图的透明通道遮罩，呈现羽化效果</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/shapeMask.gif">
                                    <span class="gongneng-name">&nbsp;形状遮罩</span>
                                    <div class="gongneng-span-name">
                                        <span
                                            class="span-name2">从此我的生命只映出你的形状。<br>支持形状遮罩</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/physics.gif">
                                    <span class="gongneng-name">&nbsp;物理</span>
                                    <div class="gongneng-span-name">
                                        <span
                                            class="span-name2">支持物理约束，重力，风力，惯性。<br>从此我的心跟着你摇摆。</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/transformConstrain.gif">
                                    <span class="gongneng-name">&nbsp;变换约束</span>
                                    <div class="gongneng-span-name">
                                        <span
                                            class="span-name2">你动我也动，相爱永相随。<br>支持变换约束，可跟随其他骨骼的旋转，平移，缩放</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4 dbmoreItem">
                                <div class="texing1">
                                    <img src="../assets/img/animation/more/10.png">
                                    <span class="gongneng-name">&nbsp;导入导出</span>
                                    <div class="gongneng-span-name">
                                        <span
                                            class="span-name2">导入psd文件、图片、纹理集、龙骨数据格式、Spine等第三方动画格式。<br>导出纹理集、序列帧、龙骨动画数据，spine动画数据</span>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="sign-up">
                <a :href="signUpUrl">立刻注册体验</a>
            </div>
        </section>
        <section class="what">
            <div class="container-fluid">
                <div class="row">
                    <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
                        <!-- Indicators -->
                        <ol class="carousel-indicators" style="display: none;">
                            <li data-target="#carousel-example-generic" data-slide-to="0" class="active"></li>
                            <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                            <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                        </ol>

                        <!-- Wrapper for slides -->
                        <div class="carousel-inner" role="listbox">
                            <div class="shard"></div>
                            <div>
                                <div class="carousel-caption">
                                    <h1>示例推荐</h1>
                                    <h3>我们制作了一些非常简单的示例用于展示游戏性与互动体验。</h3>
                                    <!-- <h3><span class="demoWin alink">观看当前示例 &gt;</span></h3> -->
                                </div>
                            </div>
                            <div class="item active" urlw='480' urlh='600'
                                url='http://sedn.egret.com/examples/dbdemo_1.4/release/index.html'>
                                <img src="../assets/img/demo/1.jpg" style="    width: 100%;" />
                                <div class="carousel-caption">

                                </div>
                            </div>
                            <div class="item" urlw='480' urlh='600'
                                url='http://edn.egret.com/cn/example/page/examples/1/160-db-mech/bin-release/web/001/index.html?v=1'>
                                <img src="../assets/img/demo/2.jpg" style="    width: 100%;" />
                                <div class="carousel-caption">

                                </div>
                            </div>
                            <div class="item" urlw='480' urlh='600'
                                url='http://sedn.egret.com/examples/tcldemo_5/release/index.html'>
                                <img src="../assets/img/demo/3.jpg" style="    width: 100%;" />
                                <div class="carousel-caption">

                                </div>
                            </div>
                        </div>

                        <!-- Controls -->
                        <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                            <img src="../assets/img/left.png" />
                        </a>
                        <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                            <img src="../assets/img/right.png" />
                        </a>
                    </div>


                </div>
            </div>
        </section>
        <section class="twoThree">
            <div class="container">
                <h3>数据格式和平台支持</h3>
                <div class="des">
                    <p>龙骨动画 可以输出多种格式，视频、网页、动画数据</p>
                    <p>可以用于几乎目前所有的主流游戏引擎和编程语言</p>
                    <p>因为 龙骨动画 提供了各个平台的运行库</p>
                </div>
                <div class="icon-list">
                    <div class="col-md-2 col-xs-4"><img src="../assets/img/platform/1.png" /></div>
                    <div class="col-md-2 col-xs-4"><img src="../assets/img/platform/2.png" /></div>
                    <div class="col-md-2 col-xs-4"><img src="../assets/img/platform/3.png" /></div>
                    <div class="col-md-2 col-xs-4"><img src="../assets/img/platform/4.png" /></div>
                    <div class="col-md-2 col-xs-4"><img src="../assets/img/platform/5.png" /></div>
                    <div class="col-md-2 col-xs-4"><img src="../assets/img/platform/6.png" /></div>
                </div>
                <div class="runtime">
                    <p style="padding-top: 100px;">
                        <a class="alink" href="https://github.com/DragonBones/">深入了解各平台运行库 ></a>
                    </p>
                </div>

            </div>
        </section>
        <section class="fourCase">
            <div class="container">
                <h3>成功案例</h3>
                <h4>他们都是用龙骨动画开发的</h4>

                <div class="texing22 texing">
                    <div class="" style="display: inline-block;">

                        <div class="col-xs-6 col-m-6 col-lg-4 dbmoreItem">
                            <div class="texing1">
                                <img src="../assets/img/case/1.jpg">
                                <span class="gongneng-name">&nbsp;《太极》</span>
                                <div class="gongneng-span-name">
                                    <span
                                        class="span-name2">《太极》是一款战斗酷炫的动作格斗游戏。单线横版战斗，自由组合战技,配合特色的多个魔神大招，独创奥义技能，战斗更畅快。</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-6 col-lg-4 dbmoreItem">
                            <div class="texing1 center-block">
                                <img src="../assets/img/case/2.jpg">
                                <span class="gongneng-name">&nbsp;《无尽远征》</span>
                                <div class="gongneng-span-name">
                                    <span
                                        class="span-name2">《无尽远征》是一款2D横版MMO手游，多种职业策略搭配自选技能，装备属性随机掉落，自由买卖的线上交易所，多达20人同时参与的大型副本。</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-6 col-lg-4 dbmoreItem">
                            <div class="texing1">
                                <img src="../assets/img/case/3.jpg">
                                <span class="gongneng-name">&nbsp;《刀塔传奇》</span>
                                <div class="gongneng-span-name">
                                    <span class="span-name2">《刀塔传奇》是一款动作卡牌手机游戏，玩家可以收集到Q版英雄，通过装备附魔、技能升级、英雄进阶提升战队战斗力。</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="clearfix "></div>
            </div>
        </section>
        <div>
            <div class="sign-up">
                <a :href="signUpUrl">立刻注册体验</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from 'vue'
import { GlobalDataProps } from '../store/index'
import { useStore } from 'vuex'
export default defineComponent({
    setup() {
        const store = useStore<GlobalDataProps>()
        const user = computed(() => store.state.user)
        const signUpUrl = user.value.isLogin ? '/works' : '/signUp'
        const loginUrl = user.value.isLogin ? '/works' : '/login'
        onMounted(() => {
            const tArr = ['功能全面', '打开即用', 'AI辅助动画', '多语言支持', '跨平台', '运行库开源免费']
            let i = 0
            const kaiyuan = document.getElementById('kaiyuan')
            if (!kaiyuan) return
            setInterval(function () {
                kaiyuan.innerHTML = tArr[i]
                i++
                if (i >= tArr.length) {
                    i = 0
                }
            }, 1500)

            let curIndex = 0
            // 找到class是left carousel-control的元素
            const left = document.querySelector('.left.carousel-control')
            if (!left) return
            left.addEventListener('click', function () {
                // 找到 class是 carousel-inner 中的子元素 class是item的元素
                const items = document.querySelectorAll('.carousel-inner .item')
                curIndex += 1
                if (curIndex >= items.length) {
                    curIndex = 0
                }
                items.forEach((item, index) => {
                    if (index === curIndex) {
                        item.classList.add('active')
                    } else {
                        item.classList.remove('active')
                    }
                })
            })
            // 找到class是right carousel-control的元素
            const right = document.querySelector('.right.carousel-control')
            if (!right) return
            right.addEventListener('click', function () {
                // 找到 class是 carousel-inner 中的子元素 class是item的元素
                const items = document.querySelectorAll('.carousel-inner .item')
                curIndex -= 1
                if (curIndex < 0) {
                    curIndex = items.length - 1
                }
                items.forEach((item, index) => {
                    if (index === curIndex) {
                        item.classList.add('active')
                    } else {
                        item.classList.remove('active')
                    }
                })
            })
        })
        return {
            user,
            signUpUrl,
            loginUrl
        }
    }
})
</script>

<style>

.nezha-home a, a:hover, a:focus {
    text-decoration: none;
    outline: none;
    color: #FFF !important;
}

.videoSec {
    position: relative;
    background: url(../assets/img/topbanner.jpg) no-repeat;
    background-size: cover;
}

.videoSec video {
    margin: -2px 0;
}

.videoSec .videoCon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
}

.videoSec .videoCon .btnPlay {
    font-size: 20px;
}

.bgPattern {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
}

.tableDisplay {
    display: table;
    height: 100%;
    width: 100%;
}

.vCenter {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding-left: 150px;
}

.vCenter .des {
    margin-top: 60px;
    margin-bottom: 45px;
    color: #f5f6f7;
    font-size: 20px;
}

.vCenter .des p {
    font-size: 20px;
    line-height: 35px;
    margin: 0 0 0px;
}

.videoSec h1 {
    font-size: 50px;
    color: #FFF;
    margin: -100px 0 25px;
    display: inline-block;
    /*text-shadow: 2px 2px 0px rgba(0, 0, 0, .5);*/
    width: 100%;
}

.btnPlay {
    margin-top: 20px;
}

.btnPlay img {
    width: 35px;
    cursor: pointer;
}

.oneFeature {
    background-color: #EEE;
    padding-top: 120px;
    padding-bottom: 120px;
}

.oneFeature h3 {
    color: #5fdab5;
    font-size: 26px;
    margin-bottom: 28px;
}

.oneFeature p {
    color: #757575;
    font-size: 14px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
}

:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.what,
.who,
.getStarted {

    text-align: center;
    color: #4C4C4E;
}

.what h2,
.who h2,
.one-engine h2,
.high-end h2,
.getStarted h2,
.download h2 {
    text-transform: uppercase;
    font-size: 2.25em;
    font-weight: 500;
    letter-spacing: .1em;
    text-align: center;
    margin-bottom: .75em;
    color: #414141;
}

.what p,
.who p,
.one-engine p,
.high-end p,
.getStarted p,
.download p {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
}

.carousel-caption {
    right: inherit;
    left: 10%;
    top: 24%;
    padding-bottom: 30px;
    text-align: left;
    color: #FFFFFF !important;
}

.carousel-caption h3 {
    font-size: 1.1em;
    top: 80px;
    width: 360px;
}

.carousel-caption h1 {
    color: #FFFFFF !important;
}

.carousel-caption h3 {
    color: #FFFFFF !important;
}

.carousel-control img {
    width: 50px;
    height: 50px;
}

.carousel-control.left {
    width: 40px;
    right: 160px;
    left: inherit;
    height: 0px;
    bottom: 115px;
    top: inherit;
}

.carousel-control.right {
    width: 40px;
    right: 90px;
    left: inherit;
    height: 0px;
    bottom: 115px;
    top: inherit;
}

.carousel-caption h1 h2 h3 h4 {
    color: #fff;
    font-size: 20px;
    margin: 0;
    padding: 0;
    line-height: 30px;
}

.shard {
    width: 900px;
    height: 2000px;
    background: #222c37;
    background: rgba(34, 44, 55, 0.7);
    position: absolute;
    top: -170px;
    left: 190px;
    z-index: 1;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: top right;
    -moz-transform-origin: top right;
    transform-origin: top right;
}

.twoThree {
    background-color: #EEE;
    padding-top: 85px;
    padding-bottom: 85px;
    text-align: center;
}

.twoThree h3 {
    color: #414141;
    text-transform: uppercase;
    font-size: 2.25em;
    font-weight: 500;
    letter-spacing: .1em;
    text-align: center;
    margin-bottom: .75em;
}

.twoThree h4 {
    margin-top: 25px;
    color: #4A4A4A;
}

.twoThree .des {
    margin: 60px 0 60px 0;
    color: #797979;
    font-size: 20px;
}

.fourCase {
    background-image: url(../assets/img/casebg.jpg);
    padding-top: 85px;
    padding-bottom: 85px;
    text-align: center;
}

.twoThree .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* 可选：居中对齐子元素 */
}

.twoThree .container>* {
    flex: 1 1 100%;
    /* 子元素占据一行 */
    margin: 10px;
    /* 可选：子元素之间的间距 */
}

.twoThree .container .icon-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
}

.twoThree .container .runtime a {
    color: #414141 !important;
    font-size: 20px;
}

.fourCase .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* 可选：居中对齐子元素 */
}

.fourCase .container>* {
    flex: 1 1 100%;
    /* 子元素占据一行 */
    margin: 10px;
    /* 可选：子元素之间的间距 */
}

.fourCase h3 {
    color: #414141;
    text-transform: uppercase;
    font-size: 2.25em;
    font-weight: 500;
    letter-spacing: .1em;
    text-align: center;
    margin-bottom: .75em;
}

.fourCase h4 {
    margin-top: 25px;
    margin-bottom: 70px;
    color: #9E9E9E;
    font-size: 20px;
}

.fourCase img {
    margin-bottom: 20px;
    width: 95%;
}

.fourCase p {
    line-height: 31px;
}

.fourCase .dbmoreItem {
    padding-bottom: 25px;
    width: 333px;
}

.fourCase .texing1 {
    background-color: #FFFFFF;
    min-height: 361px;
    border: 1px #F3F3F3 solid;
    box-shadow: 0 0 10px rgba(141, 141, 140, 1.2);
    -webkit-box-shadow: 0 0 10px rgba(141, 141, 140, 1.2);
    -moz-box-shadow: 0 0 108px rgba(141, 141, 140, 1.2);
    text-align: left;
}

.fourCase .texing1 img {
    position: relative;
    display: block;
    width: 100%;
    height: 260px;
}

.fourCase .gongneng-name {
    font-size: 24px;
    font-weight: 500;
    padding-left: 0px;
    color: #000000;
}

.fourCase .gongneng-span-name {
    padding: 10px 10px 10px 10px;
    max-width: 518px;
}

.fourCase .span-name2 {
    color: #737373;
    font-size: 15px;
    line-height: 28px;
}

.dragonbone-title {
    margin-bottom: 50px !important;
}

.dragonbone-feature {
    color: rgb(111, 219, 136) !important;
}

.three .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* 可选：居中对齐子元素 */
}
.three .container>* {
    flex: 1 1 100%;
    /* 子元素占据一行 */
    margin: 10px;
    /* 可选：子元素之间的间距 */
}

.three {
    background-color: #EEE;
}

.three .dbmoreItem {
    padding-bottom: 25px;
}

.three .texing1 {
    background-color: #FFFFFF;
    min-height: 420px;
    border: 1px #F3F3F3 solid;
    box-shadow: 0 0 8px rgba(141, 141, 140, .2);
    -webkit-box-shadow: 0 0 8px rgba(141, 141, 140, .2);
    -moz-box-shadow: 0 0 8px rgba(141, 141, 140, .2);
}

.three .texing1 img {
    position: relative;
    display: block;
    width: 100%;
}

.three .gongneng-name {
    font-size: 24px;
    font-weight: 500;
    padding-left: 10px;
    color: #000000;
}

.three .gongneng-span-name {
    padding: 10px 20px 20px 20px;
    max-width: 518px;
}

.three .span-name2 {
    color: #737373;
    font-size: 15px;
    line-height: 28px;
}

.three h2 {
    margin-top: 65px;
    margin-bottom: 55px;
    text-align: center;
    color: #000000;

}
.sign-up {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}
.sign-up a {
    color:#414141 !important;
    font-size: 20px !important;
}
</style>