
import { defineComponent } from 'vue'
import { Card, Row, Col } from 'ant-design-vue'

export default defineComponent({
  components: {
    'a-card': Card,
    'a-row': Row,
    'a-col': Col
  }
})
