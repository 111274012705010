<template>
  <div class="contact-page">
    <h2 class="contact-title">商务合作</h2>
    <div class="contact-cards">
      <a-card class="contact-card" title="动画总监：沧月">
        <a-row>
          <a-col :span="16">
            <p>QQ: 1354300036</p>
            <p>动画问题咨询 | 动画解决方案 <br>项目孵化管理 | 产业资源整合 <br>动画生态构建 | 动画产业赋能</p>
          </a-col>
          <a-col :span="8">
            <img src="../assets/img/concat/qq-2.jpg" alt="商务总监" class="contact-image">
          </a-col>
        </a-row>
      </a-card>
      <a-card class="contact-card" title="技术总监：苏魁">
        <a-row>
          <a-col :span="16">
            <p>微信：boqingyouzi</p>
            <p>技术咨询支持 | 动画技术方案<br> 私有本地部署 | AI模型赋能 <br>战略合作开发 | 商业模式咨询 </p>
          </a-col>
          <a-col :span="8">
            <img src="../assets/img/concat/wechat-1.jpg" alt="技术总监" class="contact-image">
          </a-col>
        </a-row>
      </a-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Card, Row, Col } from 'ant-design-vue'

export default defineComponent({
  components: {
    'a-card': Card,
    'a-row': Row,
    'a-col': Col
  }
})
</script>

<style>
.contact-page {
  height: 80vh;
  background-color: #fcfcfc;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-title {
  color: #000;
  text-align: center;
  padding-top: 20px;
}

.contact-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.contact-card {
  width: 400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden; /* Ensure content does not overflow the card */
}

.contact-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.contact-card p {
  margin: 10px 0;
  color: #333;
}

.contact-image {
  max-height: 300px;
  width: 100%;
  object-fit: cover; /* Ensure the image covers the area without stretching */
  border-radius: 10px;
}
</style>