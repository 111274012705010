
import { defineComponent, PropType } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { message, Modal } from 'ant-design-vue'
import { UserProps } from '../store/user'
import { gotoEditor } from '../utils/utils'
export default defineComponent({
    name: 'user-profile',
    props: {
        user: {
            type: Object as PropType<UserProps>,
            required: true
        }
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const gotoPayForVIP = () => {
            router.push('/pay')
        }
        const gotoPayForSpace = () => {
            router.push('/paySpace')
        }
        const createDesign = async () => {
            axios.post('/work/new').then((res) => {
                if (res.data) {
                    if (res.data.data) {
                        const workId = res.data.data.uuid
                        gotoEditor(workId)
                    }
                    else {
                        message.error('创建作品失败')
                    }
                }
                else {
                    message.error('创建作品失败')
                }
            }).catch((err) => {
                console.log('err', err)
                if(err.errno) {
                    if (err.errno === 101015) {
                        Modal.confirm({
                            title: 'VIP会员到期',
                            content: 'VIP会员已经到期，请续费充值会员。',
                            okText: '去充会员',
                            onOk() {
                                gotoPayForVIP()
                            }
                        });
                    }
                    else if (err.errno === 101016) {
                        Modal.confirm({
                            title: '数据容量超过限制',
                            content: '数据容量超过限制，请续费增加容量。',
                            okText: '去充容量',
                            onOk() {
                                gotoPayForSpace()
                            }
                        });
                    }
                }
                else {
                    message.error('创建作品失败')
                }
            })
        }
        const logout = () => {
            store.commit('logout')
            message.success('退出登录成功，2秒后跳转到首页', 2)
            setTimeout(() => {
                router.push('/')
            }, 2000)
        }
        return {
            logout,
            createDesign
        }
    }
})
