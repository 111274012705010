
import { defineComponent, PropType, computed, ref, watch } from 'vue'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { TemplateProps } from '../store/templates'
import { Modal, Input } from 'ant-design-vue'
import { gotoEditor } from '@/utils/utils'
export default defineComponent({
  name: 'works-list',
  emits: ['on-copy', 'on-delete', 'on-rename'],
  components: {
    EditOutlined,
    DeleteOutlined,
    Input,
  },
  props: {
    list: {
      type: Array as PropType<TemplateProps[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    transferStatus: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {

    const renameOpen = ref(false)
    const curName = ref('')
    const newName = ref('')
    const newNameId = ref('')
    const onNameChange = (e: any) => {
      newName.value = e.target.value
    }
    const deleteClicked = (id: string) => {
      Modal.confirm({
        title: '确定要删除该作品吗？',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          context.emit('on-delete', id)
        }
      })
    }
    const copyClicked = (id: number) => {
      context.emit('on-copy', id)
    }
    const editWork = (id: string) => {
      gotoEditor(id)
    }
    const rename = (id: string) => {
      newNameId.value = id
      curName.value = props.list.find(item => item.uuid === id)?.title || ''
      renameOpen.value = true;
    }
    const renameWork = () => {
      console.log('rename', newName.value)
      if(!newName.value) {
        return
      }
      if(newName.value === curName.value) {
        renameOpen.value = false
        return
      }

      console.log('rename', newName.value)
      context.emit('on-rename', newNameId.value, newName.value)
      renameOpen.value = false
    }
    return {
      deleteClicked,
      copyClicked,
      editWork,
      rename,
      curName,
      newName,
      newNameId,
      renameOpen,
      onNameChange,
      renameWork
    }
  }
})
