import axios, { AxiosRequestConfig } from 'axios'
import { Module, ActionContext } from 'vuex'
import { GlobalDataProps, actionWrapper } from './index' 
import { RespData } from './respTypes'

export interface GoodsData {
  name: string;
  _id: string;
  desc: string;
  type: number;
  duration: number;
  space: number;
  currency: number;
  price: number;
  originPrice: number;
}
interface OrderData {
  name: string;
  _id: string;
  goods: GoodsData;
}
export interface PayDataProps {
  goods?: GoodsData[];
  order?: OrderData;
  payUrl?: string;
  price?: number;
  payStatus?: 'success' | 'fail' | 'pending';
}

export interface PayProps {
  isLogin: boolean;
  token?: string;
  data: PayDataProps;
}

const pay: Module<PayProps, GlobalDataProps> = {
  state: {
    isLogin: false,
    data: {},
    token: localStorage.getItem('token') || ''
  },
  mutations: {
    getGoods(state, rawData: RespData< {list: GoodsData[]}>) {
      state.data.goods = rawData.data.list
      console.log('getGoods', state.data.goods)
    },
    getWXPayCode(state, rawData: RespData<{payCode: string; order: OrderData; price: number}>) {
      console.log('getWXPayCode', rawData.data)
      state.data.payUrl = rawData.data.payCode
      state.data.price = rawData.data.price
      state.data.order = rawData.data.order
    },
    getPayOrderStatus(state, rawData: RespData<{status: 'success' | 'fail' | 'pending'}>) {
      state.data.payStatus = rawData.data.status
    },
  },
  actions: {
    getGoods: actionWrapper('/order/goods', 'getGoods'),
    getOrder: actionWrapper('/pay/getOrder', 'getOrder'),
    getWXPayCode: actionWrapper('/order/getWXPayCode', 'getWXPayCode', { method: 'get'}),
    getPayStatus: actionWrapper('/order/getPayOrderStatus', 'getPayOrderStatus'),
    testPay: actionWrapper('/order/testPay', 'testPay', { method: 'post'}),
    // login({ commit }, payload) {
    //   return axios.post('/users/loginByPhoneNumber', payload).then(rawData => {
    //     commit('login', rawData.data)
    //   })
    // },
    // signup: actionWrapper('/user/signup', 'signup', { method: 'post'}),
    // forgetPassword: actionWrapper('/user/forgetPassword', 'forgetPassword', { method: 'post'}),
    // fetchCurrentUser: actionWrapper('/user/getUserInfo', 'fetchCurrentUser'),
    // loginAndFetch({ dispatch }, loginData) {
    //   console.log('loginAndFetch')
    //   return dispatch('login', loginData).then(() => {
    //     return dispatch('fetchCurrentUser')
    //   })
    // },
    // signUpAndFetch({ dispatch }, signupData) {
    //   return dispatch('signup', signupData).then(() => {
    //     return dispatch('fetchCurrentUser')
    //   })
    // },
    // forgetPasswordAndFetch({ dispatch }, forgetPasswordData) {
    //   return dispatch('forgetPassword', forgetPasswordData).then(() => {
    //     return dispatch('fetchCurrentUser')
    //   })
    // },
  }
}

export default pay