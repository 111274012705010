
import { defineComponent, onMounted, computed } from 'vue'
import { GlobalDataProps } from '../store/index'
import { useStore } from 'vuex'
export default defineComponent({
    setup() {
        const store = useStore<GlobalDataProps>()
        const user = computed(() => store.state.user)
        const signUpUrl = user.value.isLogin ? '/works' : '/signUp'
        const loginUrl = user.value.isLogin ? '/works' : '/login'
        onMounted(() => {
            const tArr = ['功能全面', '打开即用', 'AI辅助动画', '多语言支持', '跨平台', '运行库开源免费']
            let i = 0
            const kaiyuan = document.getElementById('kaiyuan')
            if (!kaiyuan) return
            setInterval(function () {
                kaiyuan.innerHTML = tArr[i]
                i++
                if (i >= tArr.length) {
                    i = 0
                }
            }, 1500)

            let curIndex = 0
            // 找到class是left carousel-control的元素
            const left = document.querySelector('.left.carousel-control')
            if (!left) return
            left.addEventListener('click', function () {
                // 找到 class是 carousel-inner 中的子元素 class是item的元素
                const items = document.querySelectorAll('.carousel-inner .item')
                curIndex += 1
                if (curIndex >= items.length) {
                    curIndex = 0
                }
                items.forEach((item, index) => {
                    if (index === curIndex) {
                        item.classList.add('active')
                    } else {
                        item.classList.remove('active')
                    }
                })
            })
            // 找到class是right carousel-control的元素
            const right = document.querySelector('.right.carousel-control')
            if (!right) return
            right.addEventListener('click', function () {
                // 找到 class是 carousel-inner 中的子元素 class是item的元素
                const items = document.querySelectorAll('.carousel-inner .item')
                curIndex -= 1
                if (curIndex < 0) {
                    curIndex = items.length - 1
                }
                items.forEach((item, index) => {
                    if (index === curIndex) {
                        item.classList.add('active')
                    } else {
                        item.classList.remove('active')
                    }
                })
            })
        })
        return {
            user,
            signUpUrl,
            loginUrl
        }
    }
})
