<template>
  <div class="mywork-container">
    <a-layout>
      <a-layout-sider class="mywork-slider">
        <div class="mywork-slider-content">
          <div>{{user.nickname}}</div>
          <div>{{ user.vip ? 'VIP用户': '注册用户' }}</div>
          <div class="to-bottom">
            <div :class="isExpired ? 'vip-expired': 'vip-normal'">VIP到期时间: {{ isExpired ? "(已经过期)":"" }}</div>
            <div>{{expireDate}}</div>
            <div>使用空间:</div>
            <div :class="isSpaceFull ? 'space-red' : 'space-green' ">{{((user.usedSpace || 0) / (1024 * 1024)).toFixed(2)}}MB / {{ ((user.dragonbonesSpace ||0) / (1024 * 1024)).toFixed(2) }} MB</div>
          </div>
        </div>
      </a-layout-sider>
      <a-layout>
        <a-layout-header class="mywork-header" >
          <a-row type="flex" justify="space-between" align="middle" class="poster-title" >
            <h2>作品列表</h2>
          </a-row>
        </a-layout-header>
        <a-layout-content class="mywork-content">
          <a-tabs @change="changeCategory">
          <a-tab-pane key="0" tab="我的作品">
          </a-tab-pane>
          <!-- <a-tab-pane key="1" tab="已发布作品">
          </a-tab-pane> -->
        </a-tabs>
        <a-empty v-if="works.length === 0 && !isLoading">
          <template v-slot:description>
            <span> 还没有任何作品 </span>
          </template>
          <a-button type="primary" size="large" @click.prevent="onNewWork">
            创建你的第一个作品 🎉
          </a-button>
        </a-empty>

        <works-list
          :list="works" @on-delete="onDelete" @on-rename="onRename"
          @on-copy="onCopy" :loading="isLoading"
        >
        </works-list>
        </a-layout-content>
        <a-layout-footer >
          <a-row type="flex" justify="space-between" align="middle">
            <ul class="ant-pagination">
              <li class="ant-pagination-prev" :class="{'ant-pagination-disabled': isFirstPage}">
                <a class="ant-pagination-item-link" @click.prevent="loadPrevPage">
                    上一页
                </a>
              </li>
              <li v-for="item in totalPage" :key="item" class="ant-pagination-item" :class="{'ant-pagination-item-active': (pageIndex + 1) === item}">
                <a @click.prevent="goToPage(item - 1)">{{item}}</a>
              </li>
              <li class="ant-pagination-next" :class="{'ant-pagination-disabled': isLastPage}">
                <a class="ant-pagination-item-link" @click.prevent="loadMorePage">
                    下一页
                </a>
              </li>

            </ul>
            <!-- <h2 class="work-pageIndex">{{pageIndex + 1}}</h2>
            <a-button type="primary" size="large" @click="loadPrevPage" v-if="!isFirstPage" :loading="isLoading">上一页</a-button>
            <a-button type="primary" size="large" @click="loadMorePage" v-if="!isLastPage" :loading="isLoading">下一页</a-button> -->
          </a-row>
        </a-layout-footer>
      </a-layout>
    </a-layout>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { GlobalDataProps } from '../store/index'
import WorksList from '../components/WorksList.vue'
import useLoadMore from '../hooks/useLoadMore'
import axios from 'axios'
import { message } from 'ant-design-vue'
import { gotoEditor } from '../utils/utils'

export default defineComponent({
  components: {
    WorksList
  },
  setup () {
    const store = useStore<GlobalDataProps>()
    const router = useRouter()
    const works = computed(() => store.state.templates.works)
    const total = computed(() => store.state.templates.totalWorks)
    const user = computed(() => store.state.user.data)
    const isLoading = computed(() => store.getters.isOpLoading('fetchWorks'))
    const isTemplate = ref(0)
    const searchParams =  computed(() => ({ pageIndex: 0, pageSize: 20, isTemplate: isTemplate.value }))
    const collapsed = ref<boolean>(false)

    onMounted(() => {
      store.dispatch('fetchWorks',  { searchParams: searchParams.value })
    })
    const { isLastPage, loadMorePage, isFirstPage, 
    loadPrevPage, pageIndex, requestParams, goToPage, totalPage } = useLoadMore('fetchWorks', total, searchParams.value)
    const onRename = (id: string, name: string) => {
      console.log('rename', id, name)
      store.dispatch('renameWork', { data: { uuid:id, name: name }})
    }
    const onDelete = (id: string) => {
      store.dispatch('deleteWork', { urlParams: { id: id }})
    }
    const onNewWork = () => {
      axios.post('/work/new' ).then((res) => {
        if (res.data && res.data.data) {
          const workId = res.data.data.uuid
          gotoEditor(workId)
          store.dispatch('fetchWorks',  { searchParams: searchParams.value })
        }
        else {
          message.error('创建作品失败')
        }
      }).catch((err) => {
        console.log('err', err)
      })
    }
    const onCopy = (id: number) => {
      store.dispatch('copyWork', id).then(({ data }) => {
        router.push(`/editor/${data.id}`)
      })
    }
    const changeCategory = (key: any) => {
      isTemplate.value = key
      pageIndex.value = 0
      requestParams.isTemplate = key
      nextTick(() => {
        store.dispatch('fetchWorks',  { searchParams: searchParams.value })
      })
    }
    const expireDate = new Date(user.value.dragonbonesExpire || Date.now()).toLocaleString()
    const isExpired = new Date(user.value.dragonbonesExpire || Date.now()).getTime() < Date.now()
    const isSpaceFull = (user.value.usedSpace || 0) > (user.value.dragonbonesSpace || 0)
    return {
      works,
      user,
      expireDate,
      onDelete,
      onRename,
      onCopy,
      isLoading,
      total,
      changeCategory,
      loadMorePage,
      isLastPage,
      isFirstPage,
      loadPrevPage,
      pageIndex,
      goToPage,
      totalPage,
      onNewWork,
      collapsed,
      isExpired,
      isSpaceFull
    }
  }
})
</script>

<style>
 .mywork-container .ant-input-search {
  width: 30%;
}
.mywork-slider.ant-layout-sider {
  text-align: center;
  width: 200px;
  background: none;
  border-right: 1px solid #bdbbbb;
}
.mywork-content.ant-layout-content {
  min-height: 67vh;
}
.mywork-slider .ant-layout-sider-children div {
  margin: 10px 0;
}
.mywork-slider .to-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.mywork-header.ant-layout-header {
  background: none;
}
.searchResult {
  display: flex;
  align-items: center;
}
#main-chart {
  position: relative
}
.chart-loading {
  position: absolute;
  left: 50%;
  top: 50%;
}
.work-pageIndex {
  user-select: none;
}
.space-red {
  color: red;
}
.space-green {
  color: green;
}
.vip-expired {
  color: red;
}
.vip-normal {
  color: green;
}
.mywork-slider-content {
  padding-top: 50px;
}
</style>
