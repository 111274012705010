<template>
  <div class="pay-page">
    <h2 class="pay-title">付费内容</h2>
    <div class="pay-content">
      <Card title="注册会员" style="width: 300px">
        <p>免费体验7天</p>
        <p>存储空间100M</p>
        <p>最多创建10个项目</p>
      </Card>

      <Card v-for="(item, index) in goods" :key="index" :title="item.name" style="width: 300px">
        <p>{{ item.name }}</p>
        <p>{{ item.desc }}</p>
        <p>创建项目无限制</p>
        <p>存储空间{{ item.space }}M</p>
        <p><span>原价</span><span class="slash">{{ item.originPrice || item.price }}</span></p>
        <p>促销价<span class="price">{{ item.price }}元</span></p>
        <button @click="gotoPay(index)">支付{{ item.price }}购买</button>
      </Card>

      <Card title="私有化部署" style="width: 300px">
        <p>适用于有私有云部署需求企业或集团</p>
        <p>灵活定制满足需求</p>
        <p>详细咨询商务</p>
      </Card>
      <!-- <Card title="包月会员" style="width: 300px">
        <p>30天</p>item.price
        <p>创建项目无限制</p>
        <p>存储空间1G</p>
        <p>促销价<span class="price">19.9元</span></p>
        <p><span>原价</span><span class="slash">99.9</span></p>
        <button @click="gotoPay">支付19.9购买</button>
      </Card>
      <Card title="季度会员" style="width: 300px">
        <p>90天</p>
        <p>创建项目无限制</p>
        <p>存储空间2G</p>
        <p>促销价<span class="price">49.9元</span></p>
        <p><span>原价</span><span class="slash">299.9</span></p>
        <button @click="gotoPay">支付49.9购买</button>
      </Card>
      <Card title="包年会员" style="width: 300px">
        <p>365天</p>
        <p>创建项目无限制</p>
        <p>存储空间4G</p>
        <p>促销价<span class="price">199.9元</span></p>
        <p><span>原价</span><span class="slash">999.9</span></p>
        <button @click="gotoPay">支付199.9购买</button>
      </Card>
      <Card title="终身会员" style="width: 300px">
        <p>永久有效</p>
        <p>创建项目无限制</p>
        <p>存储空间100G</p>
        <p>促销价<span class="price">1999.9元</span></p>
        <p><span>原价</span><span class="slash">4999.9</span></p>
        <button @click="gotoPay">支付1999.9购买</button>
      </Card> -->
    </div>>
  </div>
  <a-modal v-model:visible="isModalVisible" title="支付" okText='取消支付' :closable="false" :maskClosable="false"
    @cancel="onCancelPay" @ok = 'onCancelPay' :cancel-button-props="{  style: { display: 'none' }}">
  <div class="modal-content">
    <div class="left">
      <p>商品名称: {{ selectedItem?.name }}</p>
      <p>商品描述: {{ selectedItem?.desc }}</p>
      <p class="price-text">价格: {{ price ? price :'--' }}元</p>
      <a-form>
        <a-form-item label="优惠码">
          <a-input v-model:value="couponCode" @blur="onCouponChange" @keyup.enter="onCouponChange" placeholder="请输入优惠码" />
        </a-form-item>
        <a-form-item label="推荐人电话" :validate-status="phoneError ? 'error' : ''" :help="phoneError ? '请输入有效的电话号码' : ''">
          <a-input v-model:value="recommenderPhone" placeholder="请输入推荐人电话" @blur="onRecommenderChange" />
        </a-form-item>
      </a-form>
      <div>请在30分钟内完成支付</div>
    </div>
    <div class="right">
      <div>
        <div class="weipay-label">微信扫码付款</div>
        <img :src="qrCodeUrl" alt="二维码" />
      </div>
    </div>
    <!-- <a-button type="primary" @click="onTestPay">测试支付</a-button> -->
  </div>
</a-modal>
<a-modal v-model:visible="isSuccessModalVisible" title="支付成功" @ok="onPaySuccess">
    <p>恭喜你！升级会员成功，你获得了{{order?.goods?.desc || 'vip会员'}}! 点击OK按钮返回首页</p>
  </a-modal>
</template>
<script lang="ts">
import { Card, Modal } from 'ant-design-vue'
import { defineComponent, watch, ref, onMounted, onUnmounted } from 'vue'
import { GlobalDataProps } from '../store/index'
import { Store, useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { GoodsData } from '../store/pay'
import QRCode from 'qrcode'
export default defineComponent({
  components: { Card },
  setup() {
    const store = useStore<GlobalDataProps>()
    const router = useRouter()
    const goods = ref(store.state.pay.data.goods)
    const order = ref(store.state.pay.data.order)
    const payUrl = ref(store.state.pay.data.payUrl)
    const price = ref(store.state.pay.data.price)
    const payStatus = ref(store.state.pay.data.payStatus)
    const isSuccessModalVisible = ref(false)
    if (!goods.value) {
      store.dispatch('getGoods')
    }

    watch(
      () => store.state.pay.data.goods,
      (newGoods) => {
        goods.value = newGoods
        console.log("goods", goods.value)
      }
    )
    const isModalVisible = ref(false)
    const selectedItem = ref<GoodsData | null>(null)
    const couponCode = ref('')
    const recommenderPhone = ref('')
    const phoneError = ref(false)
    const qrCodeUrl = ref('')
    let timer: NodeJS.Timeout | null = null
    let startTimer = false
    const getWXPayCode = async () => {
      const urlParams = {
        goodsId: selectedItem.value?._id,
        couponCode: couponCode.value,
        recommenderPhone: recommenderPhone.value
      }
      console.log("urlParams", urlParams)
      await store.dispatch('getWXPayCode', {searchParams: urlParams})
    }

    const generateQRCode = async (url: string) => {
      try {
        qrCodeUrl.value = await QRCode.toDataURL(url)
        console.log("qrCodeUrl", qrCodeUrl.value)
      } catch (err) {
        console.error(err)
      }
    }
    watch(
      [() => store.state.pay.data.order, ()=> store.state.pay.data.payUrl, ()=> store.state.pay.data.price, ()=> store.state.pay.data.payStatus],
      ([newOrder, newPayUrl, newPrice]) => {
        order.value = newOrder as any
        payUrl.value = newPayUrl as string
        price.value = newPrice as number
        payStatus.value = store.state.pay.data.payStatus
        generateQRCode(payUrl.value)
      }
    )

    const startCheckOrder = () => {
      if (startTimer) {
        return
      }
      startTimer = true
      console.log("startCheckOrder", order.value)
      timer = setInterval(() => {
        if(order.value && order.value._id) {
          store.dispatch('getPayStatus', {searchParams: {orderId: order.value._id}}).then(() => {
            console.log("payStatus", payStatus.value)
            if (payStatus.value === 'success') {
              clearInterval(timer as any)
              timer = null
              startTimer = false
              isModalVisible.value = false
              isSuccessModalVisible.value = true
              store.dispatch('fetchCurrentUser')
            }
          })
        }
      }, 5000)
    }
    const stopCheckOrder = () => {
      if(timer) {
        console.log("stopCheckOrder", timer)
        clearInterval(timer)
        timer = null
        startTimer = false
      }
    }

    const gotoPay = (index: number) => {
      // 如果没有登录，跳转到登录页面
      if (!store.state.user.isLogin) {
        router.push({ path: '/login', query: { redirect: router.currentRoute.value.fullPath } })
      } else {
        // 弹框支付
        if (goods.value) {
            selectedItem.value = goods.value[index]
          }
        getWXPayCode().then(() => {
          isModalVisible.value = true
          startCheckOrder()
        })
        isModalVisible.value = true
      }
    }
    const onCouponChange = (e: any) => {
      qrCodeUrl.value = ''
      price.value = 0
      couponCode.value = e.target.value
      getWXPayCode()
    }
    const onRecommenderChange = (e: any) => {
      if (!/^1[3-9]\d{9}$/.test(recommenderPhone.value)) {
        phoneError.value = true
      } else {
        phoneError.value = false
        recommenderPhone.value = e.target.value
        getWXPayCode()
      }
      
    }
    
    const onPaySuccess = () => {
      isSuccessModalVisible.value = false
      router.push('/')
    }
    const onCancelPay = () => {
      return new Promise((resolve, reject) => {
        Modal.confirm({
          maskClosable: false,
          title: '确定要取消支付吗？',
          onOk() {
            isModalVisible.value = false
            stopCheckOrder()
            resolve(true)
          },
          onCancel() {
            reject(false)
          }
        })
      })
    }
    // const onTestPay = () => {
    //   if (order.value) {
    //     store.dispatch('testPay', {data: {orderId: order.value._id}})
    //   }
    // }
    onUnmounted(() => {
      stopCheckOrder()
    })
    return {
      goods,
      gotoPay,
      price,
      qrCodeUrl,
      isModalVisible,
      selectedItem,
      couponCode,
      recommenderPhone,
      phoneError,
      onCouponChange,
      onRecommenderChange,
      isSuccessModalVisible,
      onPaySuccess,
      order,
      onCancelPay,
    }
  },
  methods: {
    // gotoPay(index: number) {
    //   const store = useStore<GlobalDataProps>()
    //   // 如果没有登录，跳转到登录页面
    //   if (!store.state.user.isLogin) {
    //     this.$router.push('/login')
    //   } else {
    //     // 弹框支付
    //   }
    // }
  }
})
</script>
<style>
.pay-page {
  height: 80vh;
  background-color: #fcfcfc;
  background-size: cover;
  background-repeat: no-repeat;
}

.pay-title {
  color: #000;
  text-align: center;
  padding-top: 20px;
}

.pay-content {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}

.pay-content .ant-card {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.slash {
  text-decoration: line-through;
}

.pay-qcode {
  width: 50%;
  margin: auto;
  text-align: center;
  display: flex;
}

.price {
  color: red;
  font-size: 20px;
}
.modal-content {
  display: flex;
}
.modal-content .left {
  flex: 1;
}
.modal-content .right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content img {
  max-width: 100%;
  height: auto;
}
.modal-content {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.modal-content .price-text {
  font-size: 20px;
  color: rgb(4, 154, 255);
}
.modal-content .weipay-label {
  font-size: 20px;
  margin-left: 10px;
  margin-bottom: 30px;
}
</style>
  