<template>
  <div class="works-list-component">
    <a-skeleton v-if="loading"/>
    <div class="poster-list" v-else>
      <div class="poster-item" v-for="item in list" :key="item.id">
      <!-- <a-col :span="4" v-for="item in list" :key="item.id" class="poster-item"> -->
        <a-card hoverable @click="editWork(item.uuid)">
          <template v-slot:cover>
            <img :src="item.coverImg"  v-if="item.coverImg" />
            <img src="../assets/logo192.png"  v-else />
            <!-- <div class="hover-item">
              <router-link :to="`/editor/${item.id}`"><a-button size="large" type="primary">继续编辑</a-button></router-link>
            </div> -->
          </template>
          <template class="ant-card-actions" v-slot:actions>
            <span @click.stop="rename(item.uuid)"><EditOutlined key="edit" /></span>
            <span @click.stop="deleteClicked(item.uuid)"><DeleteOutlined key="delete" /></span>
          </template>
          <a-card-meta :title="item.title">
          </a-card-meta>
        </a-card>
        <div class="tag-list">
          <a-tag color="red" v-if="item.status === 1">
            未发布
          </a-tag>
          <a-tag color="green" v-if="item.status === 2">
            已发布
          </a-tag>
        </div>
        <div class="rename">
          <a-modal v-model:visible="renameOpen" title="改名" @ok="renameWork">
            <Input :defaultValue="curName" @change="onNameChange" />
          </a-modal>
        </div>
      <!-- </a-col> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, watch } from 'vue'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { TemplateProps } from '../store/templates'
import { Modal, Input } from 'ant-design-vue'
import { gotoEditor } from '@/utils/utils'
export default defineComponent({
  name: 'works-list',
  emits: ['on-copy', 'on-delete', 'on-rename'],
  components: {
    EditOutlined,
    DeleteOutlined,
    Input,
  },
  props: {
    list: {
      type: Array as PropType<TemplateProps[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    transferStatus: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {

    const renameOpen = ref(false)
    const curName = ref('')
    const newName = ref('')
    const newNameId = ref('')
    const onNameChange = (e: any) => {
      newName.value = e.target.value
    }
    const deleteClicked = (id: string) => {
      Modal.confirm({
        title: '确定要删除该作品吗？',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          context.emit('on-delete', id)
        }
      })
    }
    const copyClicked = (id: number) => {
      context.emit('on-copy', id)
    }
    const editWork = (id: string) => {
      gotoEditor(id)
    }
    const rename = (id: string) => {
      newNameId.value = id
      curName.value = props.list.find(item => item.uuid === id)?.title || ''
      renameOpen.value = true;
    }
    const renameWork = () => {
      console.log('rename', newName.value)
      if(!newName.value) {
        return
      }
      if(newName.value === curName.value) {
        renameOpen.value = false
        return
      }

      console.log('rename', newName.value)
      context.emit('on-rename', newNameId.value, newName.value)
      renameOpen.value = false
    }
    return {
      deleteClicked,
      copyClicked,
      editWork,
      rename,
      curName,
      newName,
      newNameId,
      renameOpen,
      onNameChange,
      renameWork
    }
  }
})
</script>
<style>
.poster-list {
  display: flex;
  flex-wrap: wrap;
}
.poster-item {
  width: 100px;
  margin: 20px;
}
.poster-item  .ant-card-body {
  padding: 10px !important;
  font-size: 12px;
}
.poster-item .ant-card-meta-title {
  font-size: 12px;
}
</style>
