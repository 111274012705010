<template>
  <div class="homepage-container">
    <a-layout :style="{ background: '#fff' }">
      <a-layout-header class="header fixed-header">
        <div class="page-title">
          <router-link to="/" class="page-tab">龙骨主页</router-link>
          <router-link to="/pay" class="page-tab">会员订阅</router-link>
          <router-link to="/about" class="page-tab">关于我们</router-link>
          <router-link to="/contact" class="page-tab">联系我们</router-link>
          <router-link to="/cooperation" class="page-tab">商务合作</router-link>
          <a :href="docUrl"  class="page-tab">产品文档</a>
        </div>
        <user-profile :user="user"></user-profile>
      </a-layout-header>
      <a-layout-content class="home-layout">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
    <a-layout-footer>
      <div class="nezha-foot">
        <div class="">
          <span class="nezha-email">{{`版权所有 © 2024-${new Date().getFullYear()} `}}{{footer}}</span>
          <span class="nezha-email">邮箱: nezhayuhu@126.com</span>
          <span class="nezha-email">官方QQ群：635614717</span>
          <span>微信：boqingyouzi</span>
        </div>
        <a href="http://beian.miit.gov.cn/">{{ beian }}</a>
      </div>
    </a-layout-footer>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'
import { GlobalDataProps } from '../store/index'
import UserProfile from '../components/UserProfile.vue'
export default defineComponent({
  name: 'Index',
  components: {
    UserProfile
  },
  setup() {
    const store = useStore<GlobalDataProps>()
    const user = computed(() => store.state.user)
    const url = window.location.href
    let beian = ''
    let footer = '北京哪吒互娱科技有限公司 '
    if(url.indexOf('dragonbones.cn') > -1) {
      beian = '京公网安备11011402054398号  京ICP备2024092089号'
      footer = '北京哪吒互娱科技有限公司 '
    } else if(url.indexOf('loongbones.com') > -1) {
      beian = '京公网安备11011302007394号  京ICP备2024100042号 经营许可京B2-20250433'
      footer = '北京哪吒互娱科技有限公司 '
    }
    else {
      beian = '京公网安备11011402054398号  京ICP备2024092089号'
    }
    const origin = window.location.origin;
    const docUrl = origin + '/doc'
    onMounted(() => {
      store.dispatch('getGoods')
    })
    return {
      user, beian, docUrl,footer
    }
  }
})
</script>

<style>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  /* 确保 header 在其他内容之上 */
}

.page-title {
  color: #fff;
}
.page-tab {
  color: #fff;
  margin-right: 20px;
}

.ant-layout-footer {
  text-align: center;
}
.nezha-foot {
  justify-content: space-between;
  padding: 10px 0;
  color: #726a6a;
}
.nezha-foot  a {
  color: #726a6a !important;
}
.nezha-email {
  margin-right: 20px;
}
</style>
