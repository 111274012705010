import { Module } from 'vuex'
import { GlobalDataProps, actionWrapper } from './index'
import { RespListData, RespData } from './respTypes'
import { PageData } from './editor'

export type TemplateProps = Required<Omit<PageData, 'props' | 'setting'>>

export interface TemplatesProps {
  data: TemplateProps[];
  totalTemplates: number;
  works: TemplateProps[];
  totalWorks: number;
}

const templates: Module<TemplatesProps, GlobalDataProps> = {
  state: {
    data: [],
    totalTemplates: 0,
    works: [],
    totalWorks: 0
  },
  mutations: {
    fetchTemplates(state, rawData: RespListData<TemplateProps>) {
      const { count, list } = rawData.data
      state.data = [ ...state.data, ...list ]
      state.totalTemplates = count
    },
    fetchWorks(state, rawData: RespListData<TemplateProps>) {
      const { count, list } = rawData.data
      state.works = list
      state.totalWorks = count
    },
    fetchTemplate(state, rawData: RespData<TemplateProps>) {
      state.data = [rawData.data]
    },
    deleteWork(state, rawData: RespData<any>) {
      const uuid = rawData.data.uuid
      state.works = state.works.filter(t => t.uuid !== (uuid))
    },
    renameWork(state, rawData: RespData<any>) {
      const { uuid, title } = rawData.data;
      console.log('rename work', rawData.data)
      const work = state.works.find(t => t.uuid === uuid)
      if (work) {
        work.title = title
      }
    }
  },
  actions: {
    fetchTemplates: actionWrapper('/templates', 'fetchTemplates'),
    fetchWorks: actionWrapper('/work/list', 'fetchWorks'),
    fetchTemplate: actionWrapper('/templates/:id', 'fetchTemplate'),
    deleteWork: actionWrapper('/work/:id', 'deleteWork', { method: 'delete' }),
    renameWork: actionWrapper('/work/rename', 'renameWork', { method: 'post' })
  },
  getters: {
    getTemplateById: (state, getters, rootState) => (id: number) => {
      return state.data.find(t => t.id === id)
    }
  }
}

export default templates